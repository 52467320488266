<h2 mat-dialog-title>Error</h2>
<mat-dialog-content>
  <ul>
    <li *ngFor="let error of errors">{{ error.message }}</li>
  </ul>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button color="primary" mat-dialog-close cdkFocusInitial>
    OK
  </button>
</mat-dialog-actions>
