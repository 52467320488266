import { Injectable } from '@angular/core';
import { Action, NgxsOnInit, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs';
import { OrganisationService } from 'src/app/graphql/service/organisation.service';

const OrganisationStateName = 'ORGANISATION_STATE';

export class GetOrganisationDataAction {
  static readonly type = `[${OrganisationStateName}]: get organisation data`;
}

export type OrganisationStateModel = {
  owner: string;
  name: string;
  country: string; // TODO Country Code Type
  email: string;
  phone: string;
  vatNumber: string;
};

@State<OrganisationStateModel>({
  name: OrganisationStateName,
  defaults: {
    owner: '',
    name: '',
    country: '',
    email: '',
    phone: '',
    vatNumber: '',
  },
})
@Injectable()
export class OrganisationState implements NgxsOnInit {
  @Selector()
  public static owner(state: OrganisationStateModel) {
    return state.owner;
  }

  constructor(private organisationService: OrganisationService) {}

  public ngxsOnInit(ctx: StateContext<OrganisationStateModel>): void {
    ctx.dispatch(GetOrganisationDataAction);
  }

  @Action(GetOrganisationDataAction, { cancelUncompleted: true })
  public getOrganisationData(ctx: StateContext<OrganisationStateModel>) {
    return this.organisationService.getOrganisationData().pipe(
      tap(({ country, vatNumber, organisationContact }) =>
        ctx.patchState({
          owner: [
            organisationContact.firstname,
            organisationContact.lastname,
          ].join(' '),
          name: '', // TODO
          country,
          email: organisationContact.email,
          phone: organisationContact.phone,
          vatNumber,
        })
      )
    );
  }
}
