import { gql } from 'apollo-angular';
import { PageSize } from '../../common/common.models';
import { Where } from '../../common/gql.helper';
import { RawWebInvoice, WebInvoiceStatus } from '../models/web-invoice';

export type GetWebInvoicesVariables = {
  limit: PageSize;
  offset: number;
  createdAt: 'asc' | 'desc';
  where: Where<{
    createdAt: Date;
    status: WebInvoiceStatus;
    expirationDate: Date;
    transactionId: string;
  }>;
};

export type GetWebInvoicesExportVariables = Omit<
  GetWebInvoicesVariables,
  'limit' | 'offset'
>;

export type GetWebInvoicesResponse = {
  web_invoice: RawWebInvoice[];
  web_invoice_aggregate: {
    aggregate: {
      count: number;
    };
  };
};

export const GetWebInvoicesQuery = gql<
  GetWebInvoicesResponse,
  GetWebInvoicesVariables | GetWebInvoicesExportVariables
>`
  query GetWebInvoices(
    $limit: Int
    $offset: Int
    $createdAt: order_by!
    $where: web_invoice_bool_exp
  ) {
    web_invoice(
      limit: $limit
      offset: $offset
      order_by: { createdAt: $createdAt }
      where: $where
    ) {
      id
      amountUserCurrency
      createdAt
      description
      expirationDate
      status
      targetWalletId
      title
      userCurrency
      transaction {
        fees {
          lipaFeeMSat
          partnerFeeMSat
          networkFeeMSat
        }
        amountSat
        amountUserCurrency
        actingWallet {
          displayName
        }
      }
    }
    web_invoice_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;
