import { gql } from 'apollo-angular';
import { OrganisationData } from '../models/organisation';

type GetOrganisationDataResponse = {
  organisation: OrganisationData[];
};

export const GetOrganisationDataQuery = gql<GetOrganisationDataResponse, never>`
  query GetOrganisationDataQuery {
    organisation {
      country
      vatNumber
      organisationContact {
        email
        firstname
        lastname
        phone
      }
    }
  }
`;
