import { gql } from 'apollo-angular';
import { PageSize } from '../../common/common.models';
import { Where } from '../../common/gql.helper';
import {
  PaymentOrigin,
  RawTransaction,
  TransactionStatus,
} from '../models/transaction';

export type GetTransactionVariables = {
  limit: PageSize;
  offset: number;
  createdAt: 'asc' | 'desc';
  where: Where<{
    createdAt: Date;
    status: TransactionStatus;
    actingWalletId: number;
    paymentOrigin: PaymentOrigin;
  }>;
};

export type GetTransactionExportVariables = Omit<
  GetTransactionVariables,
  'limit' | 'offset'
>;

export type GetTransactionResponse = {
  transaction: RawTransaction[];
  transaction_aggregate: {
    aggregate: {
      count: number;
    };
  };
};

export const GetTransactionsQuery = gql<
  GetTransactionResponse,
  GetTransactionVariables | GetTransactionExportVariables
>`
  query GetTransactions(
    $limit: Int
    $offset: Int
    $createdAt: order_by!
    $where: transaction_bool_exp
  ) {
    transaction(
      limit: $limit
      offset: $offset
      order_by: { createdAt: $createdAt }
      where: $where
    ) {
      id
      actingWallet {
        displayName
      }
      amountSat
      amountUserCurrency
      status
      userCurrency
      createdAt
      paymentOrigin
      fees {
        lipaFeeMSat
        partnerFeeMSat
        networkFeeMSat
      }
    }
    transaction_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;
