export type CurrencyExchange = {
  satsPerUnit: number;
  currencyCode: CurrencyCode;
  conversionRateUpdatedAt: Date;
};

export type Exchange = {
  exchange?: {
    rate: number;
    trend: number;
  };
  exchangeFee?: number;
};

export const currencyCodes = ['CHF', 'EUR', 'USD', 'BTC', 'SAT'] as const;
export type CurrencyCode = (typeof currencyCodes)[number];

type ExchangeableTransaction = {
  userCurrency: CurrencyCode;
  amountSat: number;
  amountUserCurrency: number;
  fees: {
    lipaFeeMSat: number;
    partnerFeeMSat: number;
    networkFeeMSat: number;
  };
};

export const calculateExchange = (
  transaction: ExchangeableTransaction,
  currencyExchange: CurrencyExchange[]
): Exchange => {
  const exchange = () => {
    const ce = currencyExchange.find(
      (ce) => ce.currencyCode === transaction.userCurrency
    );

    // break if a reference currency does not exist
    if (!ce) return undefined;

    // calculated exchange rates and difference in percentage
    const transactionExchangeRate =
      (100_000_000 / transaction.amountSat) * transaction.amountUserCurrency;
    const currentExchangeRate = 100_000_000 / ce.satsPerUnit;
    const trend = (100 / transactionExchangeRate) * currentExchangeRate - 100;

    return { rate: transactionExchangeRate, trend };
  };

  const exchangeFee = () =>
    transaction.fees?.lipaFeeMSat || transaction.fees?.partnerFeeMSat
      ? ((transaction.fees.lipaFeeMSat ?? 0) +
          (transaction.fees.partnerFeeMSat ?? 0)) *
        1_000
      : undefined;

  return {
    exchange: exchange(),
    exchangeFee: exchangeFee(),
  };
};
