import { Injectable } from '@angular/core';
import { Action, NgxsOnInit, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs';
import {
  currencyCodes,
  CurrencyExchange,
} from 'src/app/graphql/models/currency-exchange';
import { CurrencyExchangeService } from 'src/app/graphql/service/currency-exchange.service';

const CurrencyExchangeStateName = 'CURRENCY_EXCHANGE_STATE';

export class GetCurrencyExchangeAction {
  static readonly type = `[${CurrencyExchangeStateName}]: get currency exchange`;
}

type CurrencyExchangeStateModel = {
  currencyExchange: CurrencyExchange[];
};

@State<CurrencyExchangeStateModel>({
  name: CurrencyExchangeStateName,
  defaults: {
    currencyExchange: [],
  },
})
@Injectable()
export class CurrencyExchangeState implements NgxsOnInit {
  @Selector()
  public static currencyExchange(state: CurrencyExchangeStateModel) {
    return state.currencyExchange;
  }

  constructor(private currencyExchangeService: CurrencyExchangeService) {}

  public ngxsOnInit(ctx: StateContext<CurrencyExchangeStateModel>): void {
    ctx.dispatch([GetCurrencyExchangeAction]);
  }

  @Action(GetCurrencyExchangeAction, { cancelUncompleted: true })
  public getCurrencyExchange(ctx: StateContext<CurrencyExchangeStateModel>) {
    return this.currencyExchangeService
      .getCurrencyExchange([...currencyCodes])
      .pipe(
        tap((currencyExchange) =>
          ctx.patchState({
            currencyExchange,
          })
        )
      );
  }
}
