import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { map } from 'rxjs';
import { throwOnGqlErrors } from 'src/app/common/rxjs.helper';
import { RefreshSessionMutation } from '../mutation/refresh-session.mutation';
import { RequestMagicLinkMutationMutation } from '../mutation/request-magic-link.mutation';
import { StartSessionMutation } from '../mutation/start-session.mutation';

@Injectable({
  providedIn: 'root',
})
export class AuthGqlService {
  constructor(private apollo: Apollo) {}

  public requestMagicLink(email: string) {
    return this.apollo
      .mutate({
        mutation: RequestMagicLinkMutationMutation,
        variables: { email },
      })
      .pipe(map((response) => response.data?.request_magic_link));
  }

  public startSession(secret: string) {
    return this.apollo
      .mutate({
        mutation: StartSessionMutation,
        variables: { secret },
        errorPolicy: 'none',
        fetchPolicy: 'network-only',
        context: {
          handleErrors: false,
        },
      })
      .pipe(
        throwOnGqlErrors(),
        map((response) => response.data?.start_magic_link_session)
      );
  }

  public refreshSession(refreshToken: string) {
    return this.apollo
      .mutate({
        mutation: RefreshSessionMutation,
        variables: { refreshToken },
        errorPolicy: 'none',
        fetchPolicy: 'network-only',
        context: {
          handleErrors: false,
        },
      })
      .pipe(
        throwOnGqlErrors(),
        map((response) => response.data?.refresh_session)
      );
  }
}
