import { importProvidersFrom } from '@angular/core';
import { Routes } from '@angular/router';
import { NgxsModule } from '@ngxs/store';
import { canAccess, canLogout } from './auth/auth.guard';
import { authResolver } from './auth/auth.resolver';
import { CurrencyExchangeState } from './common/currency-exchange/state/currency-exchange.state';
import { OrganisationState } from './common/organisation/state/organisation-state';
import { TransactionState } from './page/transactions/state/transaction.state';
import { TransactionViewConfig } from './page/transactions/transactions.models';
import { WebInvoiceState } from './page/web-invoice/state/web-invoice.state';
import {
  webInvoiceResolver,
  webInvoiceTitleResolver,
} from './page/web-invoice/web-invoice.resolver';

const defaultRoute = 'transactions';

export const appRoutes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: defaultRoute,
  },
  {
    path: 'login',
    title: 'Login',
    resolve: [authResolver],
    loadComponent: () =>
      import('./page/login/login.component').then((m) => m.LoginComponent),
  },
  {
    path: '',
    canActivate: [canAccess],
    canActivateChild: [canAccess],
    loadComponent: () =>
      import('./layout/main-layout/main-layout.component').then(
        (m) => m.MainLayoutComponent
      ),
    providers: [
      importProvidersFrom(
        NgxsModule.forFeature([
          OrganisationState,
          CurrencyExchangeState,
          TransactionState,
          WebInvoiceState,
        ])
      ),
    ],
    children: [
      {
        path: 'transactions',
        title: 'Transactions',
        loadComponent: () =>
          import('./page/transactions/transactions.component').then(
            (m) => m.TransactionsComponent
          ),
      },
      {
        path: 'pos-transactions',
        title: 'POS Transactions',
        data: {
          config: {
            paymentOrigin: 'TERMINAL',
          } as TransactionViewConfig,
        },
        loadComponent: () =>
          import('./page/transactions/transactions.component').then(
            (m) => m.TransactionsComponent
          ),
      },
      {
        path: 'web-invoice',
        children: [
          {
            path: '',
            pathMatch: 'full',
            title: 'Web Invoices',
            loadComponent: () =>
              import('./page/web-invoice/web-invoice.component').then(
                (m) => m.WebInvoiceComponent
              ),
          },
          {
            path: 'create',
            title: 'Create Invoice',
            loadComponent: () =>
              import(
                './page/web-invoice/create-web-invoice/create-web-invoice.component'
              ).then((m) => m.CreateWebInvoiceComponent),
          },
          {
            path: ':id',
            title: webInvoiceTitleResolver,
            resolve: {
              invoice: webInvoiceResolver,
            },
            loadComponent: () =>
              import(
                './page/web-invoice/web-invoice-detail/web-invoice-detail.component'
              ).then((m) => m.WebInvoiceDetailComponent),
          },
        ],
      },
      {
        path: 'account',
        title: 'Account Info',
        loadComponent: () =>
          import('./page/account/account.component').then(
            (m) => m.AccountComponent
          ),
      },
      {
        path: 'logout',
        canActivate: [canLogout],
        loadComponent: () =>
          import('./page/login/login.component').then((m) => m.LoginComponent),
      },
    ],
  },

  // catch wrong routes and redirect to default route
  {
    path: '**',
    redirectTo: defaultRoute,
  },
];
