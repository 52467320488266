import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { RouterStateSnapshot, TitleStrategy } from '@angular/router';
import { ReplaySubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LipaTitleStrategy extends TitleStrategy {
  public get clearTitle$() {
    return this.clearTitleSubject$.asObservable();
  }

  private clearTitleSubject$ = new ReplaySubject<string>(1);

  constructor(private title: Title) {
    super();
  }

  public override updateTitle(snapshot: RouterStateSnapshot): void {
    const title = this.buildTitle(snapshot);

    if (!title) return;
    this.title.setTitle(`${title} | lipa`);
    this.clearTitleSubject$.next(title);
  }
}
