import { ApolloQueryResult } from '@apollo/client';
import { MutationResult } from 'apollo-angular';
import { catchError, Observable, of, switchMap, tap, throwError } from 'rxjs';

export const onNextOrError = (callback: () => void) => {
  return <T>(source: Observable<T>) =>
    source.pipe(
      tap(() => callback()),
      catchError((err) => {
        callback();
        return throwError(() => err);
      })
    );
};

export const throwOnGqlErrors = () => {
  return <T>(source: Observable<ApolloQueryResult<T> | MutationResult<T>>) =>
    source.pipe(
      switchMap((response) =>
        response.errors ? throwError(() => response) : of(response)
      )
    );
};
