import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs';
import { onNextOrError } from 'src/app/common/rxjs.helper';
import { Transaction } from 'src/app/graphql/models/transaction';
import { GetTransactionVariables } from 'src/app/graphql/query/get-transactions.query';
import { TransactionService } from 'src/app/graphql/service/transaction.service';

export const TransactionStateName = 'TRANSACTION_STATE';

export class GetTransactionsAction {
  static readonly type = `[${TransactionStateName}]: get transactions`;
}

export class UpdateTransactionFilterAction {
  static readonly type = `[${TransactionStateName}]: update transaction filter`;

  constructor(public filter: GetTransactionVariables) {}
}

type TransactionStateModel = {
  filter: GetTransactionVariables;
  transactions: Transaction[];
  count: number;
  isLoading: boolean;
};

export const transactionStateDefaults: TransactionStateModel = {
  filter: {
    limit: 20,
    offset: 0,
    createdAt: 'desc',
    where: {
      createdAt: {
        _gt: new Date(new Date().getFullYear(), 0, 1),
      },
    },
  },
  transactions: [],
  count: 0,
  isLoading: false,
};

@State<TransactionStateModel>({
  name: TransactionStateName,
  defaults: {
    ...transactionStateDefaults,
  },
})
@Injectable()
export class TransactionState {
  @Selector()
  public static transactions(state: TransactionStateModel) {
    return state.transactions;
  }

  @Selector()
  public static filter(state: TransactionStateModel) {
    return state.filter;
  }

  @Selector()
  public static count(state: TransactionStateModel) {
    return state.count;
  }

  @Selector()
  public static isLoading(state: TransactionStateModel) {
    return state.isLoading;
  }

  constructor(private transactionService: TransactionService) {}

  @Action(UpdateTransactionFilterAction, { cancelUncompleted: true })
  public updateTransactionFilter(
    ctx: StateContext<TransactionStateModel>,
    { filter }: UpdateTransactionFilterAction
  ) {
    ctx.patchState({
      filter,
    });

    return ctx.dispatch([GetTransactionsAction]);
  }

  @Action(GetTransactionsAction, { cancelUncompleted: true })
  public async getTransactions(ctx: StateContext<TransactionStateModel>) {
    // start loading
    ctx.patchState({ isLoading: true });

    return this.transactionService.getTransactions(ctx.getState().filter).pipe(
      // stop loading
      onNextOrError(() => ctx.patchState({ isLoading: false })),

      // push result into state
      tap((data) =>
        ctx.patchState({
          transactions: data.transactions,
          count: data.count,
        })
      )
    );
  }
}
