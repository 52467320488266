import { Injectable, Injector } from '@angular/core';
import {
  BehaviorSubject,
  catchError,
  finalize,
  Observable,
  of,
  switchMap,
} from 'rxjs';
import { AuthGqlService } from '../graphql/service/auth-gql.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private static ACCESS_TOKEN_KEY = 'lipa-jwt-auth-access';
  private static REFRESH_TOKEN_KEY = 'lipa-jwt-auth-refresh';

  private refreshInProgressSubject = new BehaviorSubject<boolean>(false);
  public refreshInProgress$ = this.refreshInProgressSubject.asObservable();

  public get accessToken() {
    return sessionStorage.getItem(AuthService.ACCESS_TOKEN_KEY);
  }

  public set accessToken(token: string | null) {
    if (token) {
      sessionStorage.setItem(AuthService.ACCESS_TOKEN_KEY, token);
    } else {
      sessionStorage.removeItem(AuthService.ACCESS_TOKEN_KEY);
    }
  }

  public get refreshToken() {
    return localStorage.getItem(AuthService.REFRESH_TOKEN_KEY);
  }

  public set refreshToken(token: string | null) {
    if (token) {
      localStorage.setItem(AuthService.REFRESH_TOKEN_KEY, token);
    } else {
      localStorage.removeItem(AuthService.REFRESH_TOKEN_KEY);
    }
  }

  // we're getting Apollo by injector to prevent circular DI
  constructor(private injector: Injector) {}

  public startSession(secret: string) {
    this.refreshInProgressSubject.next(true);

    return this.injector
      .get(AuthGqlService)
      .startSession(secret)
      .pipe(
        switchMap((tokens) => {
          // unsuccessfull if tokens do not exist
          if (!tokens || !tokens.accessToken || !tokens.refreshToken) {
            return of(false);
          }

          // successfully save received tokens
          this.accessToken = tokens.accessToken;
          this.refreshToken = tokens.refreshToken;
          return of(true);
        }),

        // unsuccessfull on any errors
        catchError(() => of(false)),

        finalize(() => this.refreshInProgressSubject.next(false))
      );
  }

  public refreshSession(): Observable<boolean> {
    // block refresh
    this.refreshInProgressSubject.next(true);

    // temp save refresh token
    const refreshToken = this.refreshToken;

    // remove all tokens
    this.accessToken = null;
    this.refreshToken = null;

    if (!refreshToken) return of(false);

    return this.injector
      .get(AuthGqlService)
      .refreshSession(refreshToken)
      .pipe(
        switchMap((tokens) => {
          // unsuccessfull if tokens do not exist
          if (!tokens || !tokens.accessToken || !tokens.refreshToken) {
            return of(false);
          }

          // successfully save received tokens
          this.accessToken = tokens.accessToken;
          this.refreshToken = tokens.refreshToken;

          // stop refresh blocker
          this.refreshInProgressSubject.next(false);
          return of(true);
        }),

        // unsuccessfull on any errors
        catchError(() => of(false)),

        // stop refresh blocker
        finalize(() => this.refreshInProgressSubject.next(false))
      );
  }

  public terminateSession() {
    this.accessToken = null;
    this.refreshToken = null;
  }

  public hasAccessToken() {
    return this.accessToken !== null;
  }

  public hasRefreshToken() {
    return this.refreshToken !== null;
  }

  public invalidateSession() {
    this.accessToken = null;
  }
}
