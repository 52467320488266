import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from './auth.service';

export const canAccess: CanActivateFn = () => {
  const authService = inject(AuthService);
  const router = inject(Router);

  // activate if there is a chance that user could have access
  if (authService.hasAccessToken() || authService.hasRefreshToken()) {
    return true;
  }

  // redirect to login if not
  return router.createUrlTree(['/login']);
};

export const canLogout: CanActivateFn = () => {
  const authService = inject(AuthService);
  const router = inject(Router);

  // logout
  authService.terminateSession();

  // redirect to login
  return router.createUrlTree(['/login']);
};
