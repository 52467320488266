import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ErrorResponse } from '@apollo/client/link/error';
import { ErrorDialogComponent } from './error-dialog/error-dialog.component';

export type AnyErrorType = ErrorResponse | Error;

const isErrorResponse = (error: any): error is ErrorResponse =>
  ('operation' as keyof ErrorResponse) in error &&
  ('forward' as keyof ErrorResponse) in error;

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService {
  constructor(private dialogService: MatDialog) {}

  public handleError(error: AnyErrorType) {
    if (isErrorResponse(error)) {
      this.handleGraphQLErrors(error);
    } else {
      this.displayErrors([error]);
    }
  }

  private handleGraphQLErrors({
    graphQLErrors,
    networkError,
    operation,
  }: ErrorResponse) {
    const errors: Error[] = [];

    // dont handle errors globally when specified by operation context
    if (operation.getContext()?.['handleErrors'] === false) return;

    if (networkError) errors.push(networkError);
    if (graphQLErrors) errors.push(...graphQLErrors);

    this.displayErrors(errors);
  }

  private displayErrors(errors: Error[]) {
    errors.forEach((err) => console.error(err.message, err));
    this.dialogService.open(ErrorDialogComponent, {
      data: { errors },
    });
  }
}
