import { A11yModule } from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'lipa-error-dialog',
  standalone: true,
  imports: [CommonModule, MatDialogModule, MatButtonModule, A11yModule],
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorDialogComponent {
  public errors: Error[] = [];

  constructor(@Inject(MAT_DIALOG_DATA) { errors }: { errors: Error[] }) {
    this.errors = errors;
  }
}
