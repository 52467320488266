import { gql } from 'apollo-angular';
import { CurrencyCode, CurrencyExchange } from '../models/currency-exchange';

type GetCurrencyExchangeVariables = {
  currencyCode: CurrencyCode[];
};

type GetCurrencyExchangeResponse = {
  currency: CurrencyExchange[];
};

export const GetCurrencyExchangeQuery = gql<
  GetCurrencyExchangeResponse,
  GetCurrencyExchangeVariables
>`
  query GetCurrencyExchange($currencyCode: [String!]!) {
    currency(where: { currencyCode: { _in: $currencyCode } }) {
      satsPerUnit
      currencyCode
      conversionRateUpdatedAt
    }
  }
`;
