import { gql } from 'apollo-angular';
import { CurrencyCode } from '../models/currency-exchange';

export type CreateWebInvoiceVariables = {
  amountUserCurrency: string;
  expirationDate: Date | null;
  title: string;
  userCurrency: CurrencyCode;
};

type CreateWebInvoiceResponse = {
  create_web_invoice: {
    id: string;
  };
};

export const CreateWebInvoiceMutation = gql<
  CreateWebInvoiceResponse,
  CreateWebInvoiceVariables
>`
  mutation CreateWebInvoice(
    $amountUserCurrency: String!
    $expirationDate: timestamptz
    $title: String!
    $userCurrency: String!
  ) {
    create_web_invoice(
      amountUserCurrency: $amountUserCurrency
      title: $title
      userCurrency: $userCurrency
      expirationDate: $expirationDate
    ) {
      id
    }
  }
`;
