import { gql } from 'apollo-angular';

export type GetNumOfUnpaidWebInvoicesResponse = {
  web_invoice_aggregate: {
    aggregate: {
      count: number;
    };
  };
};

export const GetNumOfUnpaidWebInvoicesQuery = gql<
  GetNumOfUnpaidWebInvoicesResponse,
  never
>`
  query GetNumOfUnpaidWebInvoices {
    web_invoice_aggregate(where: { transactionId: { _is_null: true } }) {
      aggregate {
        count
      }
    }
  }
`;
