import { gql } from 'apollo-angular';
import { Tokens } from '../models/auth';

type RefreshSessionMutationVariables = {
  refreshToken: string;
};

type RefreshSessionMutationResponse = {
  refresh_session: Tokens;
};

export const RefreshSessionMutation = gql<
  RefreshSessionMutationResponse,
  RefreshSessionMutationVariables
>`
  mutation RefreshSession($refreshToken: String!) {
    refresh_session(refreshToken: $refreshToken) {
      accessToken
      refreshToken
    }
  }
`;
