import { DatePipe, DecimalPipe } from '@angular/common';
import { importProvidersFrom, Injectable } from '@angular/core';
import {
  DateAdapter,
  MatNativeDateModule,
  MAT_DATE_LOCALE,
  NativeDateAdapter,
} from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import {
  MatFormFieldDefaultOptions,
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
} from '@angular/material/form-field';
import { bootstrapApplication } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { provideRouter, TitleStrategy } from '@angular/router';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsModule } from '@ngxs/store';

import { AppComponent } from './app/app.component';
import { appRoutes } from './app/app.routing';
import { ApolloModule } from './app/common/apollo/apollo.module';
import { LipaCurrencyPipe } from './app/common/lipa-currency/lipa-currency.pipe';
import { LipaTitleStrategy } from './app/common/title-strategy';
import { environment } from './environments/environment';

@Injectable()
class LipaDateAdapter extends NativeDateAdapter {
  override getFirstDayOfWeek(): number {
    return 1;
  }

  override parse(value: any): Date {
    switch (typeof value) {
      case 'string': {
        return this.stringToDate(value);
      }

      case 'number':
        return isNaN(value) ? this.invalid() : new Date(value);

      default:
        const timestamp = Date.parse(value);
        return isNaN(timestamp) ? this.invalid() : new Date(timestamp);
    }
  }

  private stringToDate(value: string): Date {
    const dateRegex = /^(\d{2})\.(\d{2})\.(\d{4})$/;
    const match = value.match(dateRegex);

    // check if it matches the regex
    if (!match) return this.invalid();

    // get date data
    const [, dayStr, monthStr, yearStr] = match;
    const day = parseInt(dayStr);
    const month = parseInt(monthStr);
    const year = parseInt(yearStr);

    // check if it has a correct number of months
    if (month < 1 || month > 12) return this.invalid();

    // check if it has a correct number of days in a month
    const daysInMonth = new Date(year, month, 0).getDate();
    if (day < 1 || day > daysInMonth) return this.invalid();

    // true if no check failed
    return new Date(year, month - 1, day);
  }
}

bootstrapApplication(AppComponent, {
  providers: [
    provideRouter(appRoutes),
    importProvidersFrom(
      BrowserAnimationsModule,
      ApolloModule,
      MatNativeDateModule,
      NgxsModule.forRoot([], {
        developmentMode: !environment.PROD,
        selectorOptions: {
          suppressErrors: false,
          injectContainerState: false,
        },
      }),
      NgxsReduxDevtoolsPluginModule.forRoot(),
      MatDialogModule
    ),
    DatePipe,
    LipaCurrencyPipe,
    DecimalPipe,
    { provide: DateAdapter, useClass: LipaDateAdapter },
    { provide: MAT_DATE_LOCALE, useValue: 'en-CH' },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        subscriptSizing: 'dynamic',
      } as MatFormFieldDefaultOptions,
    },
    {
      provide: TitleStrategy,
      useExisting: LipaTitleStrategy,
    },
  ],
});
