import { gql } from 'apollo-angular';
import { RawWebInvoice } from '../models/web-invoice';

type GetOneWebInvoiceVariables = {
  id: string;
};

type GetOneWebInvoiceResponse = {
  web_invoice_by_pk: RawWebInvoice;
};

export const GetOneWebInvoiceQuery = gql<
  GetOneWebInvoiceResponse,
  GetOneWebInvoiceVariables
>`
  query GetOneWebInvoice($id: uuid!) {
    web_invoice_by_pk(id: $id) {
      id
      amountUserCurrency
      createdAt
      description
      expirationDate
      status
      targetWalletId
      title
      userCurrency
      transaction {
        fees {
          lipaFeeMSat
          partnerFeeMSat
        }
        amountSat
        amountUserCurrency
      }
    }
  }
`;
