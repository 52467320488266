import { Pipe, PipeTransform } from '@angular/core';
import {
  CurrencyCode,
  currencyCodes,
} from 'src/app/graphql/models/currency-exchange';

@Pipe({
  name: 'lipaCurrency',
  standalone: true,
})
export class LipaCurrencyPipe implements PipeTransform {
  transform(
    value: number,
    currency: CurrencyCode,
    fractionDigits: number = 2
  ): string {
    // prevent empty number
    if (value === undefined) return '';

    // prevent unconvertable number
    if (typeof value !== 'number') {
      value = Number(value);
      if (isNaN(value)) return '';
    }

    // prevent wrong currency codes
    if (!currencyCodes.includes(currency))
      return Intl.NumberFormat('en-CH', {
        minimumFractionDigits: fractionDigits,
        maximumFractionDigits: fractionDigits,
      }).format(value);

    // format to lipa specs
    const afterValue = Intl.NumberFormat('en-CH', {
      style: 'currency',
      minimumFractionDigits: currency === 'SAT' ? 0 : fractionDigits,
      maximumFractionDigits: currency === 'SAT' ? 0 : fractionDigits,
      currency,
      currencyDisplay: 'code',
    }).format(value);
    return afterValue;
  }
}
