import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, Router } from '@angular/router';
import { EMPTY, Observable, of, switchMap } from 'rxjs';
import { ErrorHandlerService } from '../common/error/error-handler.service';
import { AuthService } from './auth.service';

const qpName = 'secret';

export const authResolver: ResolveFn<boolean> = (
  route: ActivatedRouteSnapshot
): Observable<boolean> => {
  const authService = inject(AuthService);
  const errorHandler = inject(ErrorHandlerService);
  const router = inject(Router);

  // proceed to login page if there is no secret
  if (!route.queryParamMap.has(qpName)) return of(true);

  // get secret
  const secret = route.queryParamMap.get(qpName) as string;

  // try to start a session
  return authService.startSession(secret).pipe(
    switchMap((success) => {
      if (success) {
        // if successfull navigate to target
        router.navigate(['/']);
        return EMPTY;
      } else {
        // if not successfull proceed to login page
        router.navigate(['/login']);
        errorHandler.handleError(
          new Error('Unable to login with this link, please try again.')
        );
        return EMPTY;
      }
    })
  );
};
