import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { map } from 'rxjs';
import { CurrencyCode } from '../models/currency-exchange';
import { GetCurrencyExchangeQuery } from '../query/get-currency-exchange.query';

@Injectable({
  providedIn: 'root',
})
export class CurrencyExchangeService {
  constructor(private apollo: Apollo) {}

  public getCurrencyExchange(currencyCode: CurrencyCode[]) {
    return this.apollo
      .query({
        query: GetCurrencyExchangeQuery,
        variables: { currencyCode },
      })
      .pipe(map((response) => response.data.currency));
  }
}
