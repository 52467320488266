import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs';
import { onNextOrError } from 'src/app/common/rxjs.helper';
import { WebInvoice } from 'src/app/graphql/models/web-invoice';
import { GetWebInvoicesVariables } from 'src/app/graphql/query/get-web-invoices.query';
import { WebInvoiceService } from 'src/app/graphql/service/web-invoice.service';

const WebInvoiceStateName = 'WEB_INVOICE_STATE';

export class GetWebInvoicesAction {
  static readonly type = `[${WebInvoiceStateName}]: get webinvoices`;
}

export class UpdateWebInvoiceFilterAction {
  static readonly type = `[${WebInvoiceStateName}]: update webinvoice filter`;

  constructor(public filter: GetWebInvoicesVariables) {}
}

type WebInvoiceStateModel = {
  filter: GetWebInvoicesVariables;
  webInvoices: WebInvoice[];
  count: number;
  isLoading: boolean;
};

@State<WebInvoiceStateModel>({
  name: WebInvoiceStateName,
  defaults: {
    filter: {
      limit: 20,
      offset: 0,
      createdAt: 'desc',
      where: {
        createdAt: {
          _gt: new Date(new Date().getFullYear(), 0, 1),
        },
      },
    },
    webInvoices: [],
    count: 0,
    isLoading: false,
  },
})
@Injectable()
export class WebInvoiceState {
  @Selector()
  public static webInvoices(state: WebInvoiceStateModel) {
    return state.webInvoices;
  }

  @Selector()
  public static filter(state: WebInvoiceStateModel) {
    return state.filter;
  }

  @Selector()
  public static count(state: WebInvoiceStateModel) {
    return state.count;
  }

  @Selector()
  public static isLoading(state: WebInvoiceStateModel) {
    return state.isLoading;
  }

  constructor(private webInvoiceService: WebInvoiceService) {}

  @Action(UpdateWebInvoiceFilterAction, { cancelUncompleted: true })
  public updateWebInvoiceFilter(
    ctx: StateContext<WebInvoiceStateModel>,
    { filter }: UpdateWebInvoiceFilterAction
  ) {
    ctx.patchState({
      filter,
    });

    return ctx.dispatch([GetWebInvoicesAction]);
  }

  @Action(GetWebInvoicesAction, { cancelUncompleted: true })
  public getWebInvoices(ctx: StateContext<WebInvoiceStateModel>) {
    ctx.patchState({ isLoading: true });

    return this.webInvoiceService.getWebInvoices(ctx.getState().filter).pipe(
      // stop loading
      onNextOrError(() => ctx.patchState({ isLoading: false })),

      // push result into state
      tap((data) =>
        ctx.patchState({
          webInvoices: data.invoices,
          count: data.count,
        })
      )
    );
  }
}
