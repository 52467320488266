import { ErrorResponse } from '@apollo/client/link/error';

export const createHeaders = (token?: string | null) => {
  return {
    headers: {
      ['Accept']: 'charset=utf-8',
      ...(token
        ? {
            ['Authorization']: 'Bearer ' + token,
          }
        : {}),
    },
  };
};

export const AUTH_OPERATIONS = ['StartSession', 'RefreshSession'];

export const isAccessTokenInvalid = (errResponse: ErrorResponse) =>
  errResponse.graphQLErrors?.some(
    (e) => e.extensions?.['code'] === 'access-denied'
  );

export const isInvalidRefreshToken = (errResponse: ErrorResponse) =>
  errResponse.operation.operationName === 'RefreshSession' &&
  errResponse.graphQLErrors?.some(
    (e) => e.extensions?.['code'] === 'access-denied'
  );

export const isSchemaValidationError = (errResponse: ErrorResponse) =>
  errResponse.graphQLErrors?.some(
    (e) => e.extensions?.['code'] === 'validation-failed'
  );
