import { gql } from 'apollo-angular';

type RequestMagicLinkMutationVariables = {
  email: string;
};

type RequestMagicLinkMutationReponse = {
  request_magic_link: boolean;
};

export const RequestMagicLinkMutationMutation = gql<
  RequestMagicLinkMutationReponse,
  RequestMagicLinkMutationVariables
>`
  mutation RequestMagicLink($email: String!) {
    request_magic_link(email: $email)
  }
`;
