import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { map } from 'rxjs';
import { GetOrganisationDataQuery } from '../query/get-organisation-data.query';

@Injectable({
  providedIn: 'root',
})
export class OrganisationService {
  constructor(private apollo: Apollo) {}

  public getOrganisationData() {
    return this.apollo
      .query({
        query: GetOrganisationDataQuery,
      })
      .pipe(map((response) => response.data.organisation[0]));
  }
}
