import { gql } from 'apollo-angular';
import { Tokens } from '../models/auth';

type StartSessionMutationVariables = {
  secret: string;
};

type StartSessionMutationResponse = {
  start_magic_link_session: Tokens;
};

export const StartSessionMutation = gql<
  StartSessionMutationResponse,
  StartSessionMutationVariables
>`
  mutation StartSession($secret: String!) {
    start_magic_link_session(magicLinkSecret: $secret) {
      accessToken
      refreshToken
    }
  }
`;
