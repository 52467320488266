import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Apollo } from 'apollo-angular';
import { filter, forkJoin, map, take } from 'rxjs';
import { CurrencyExchangeState } from '../../common/currency-exchange/state/currency-exchange.state';
import {
  calculateExchange,
  CurrencyExchange,
} from '../models/currency-exchange';
import { RawWebInvoice, WebInvoice } from '../models/web-invoice';
import {
  CreateWebInvoiceMutation,
  CreateWebInvoiceVariables,
} from '../mutation/create-web-invoice.mutation';
import { GetNumOfUnpaidWebInvoicesQuery } from '../query/get-numof-unpaid-webinvoices.query';
import { GetOneWebInvoiceQuery } from '../query/get-one-web-invoice.query';
import {
  GetWebInvoicesExportVariables,
  GetWebInvoicesQuery,
  GetWebInvoicesResponse,
  GetWebInvoicesVariables,
} from '../query/get-web-invoices.query';

@Injectable({
  providedIn: 'root',
})
export class WebInvoiceService {
  constructor(private apollo: Apollo, private store: Store) {}

  public getWebInvoices<
    T extends GetWebInvoicesVariables | GetWebInvoicesExportVariables
  >(variables: T) {
    return forkJoin([
      this.apollo
        .query<GetWebInvoicesResponse, T>({
          query: GetWebInvoicesQuery,
          variables,
          fetchPolicy: 'network-only',
        })
        .pipe(map((response) => response.data)),
      this.store.select(CurrencyExchangeState.currencyExchange).pipe(
        filter((ce) => !!ce?.length),
        take(1)
      ),
    ]).pipe(
      map(([data, ce]) => ({
        invoices: data.web_invoice.map((invoice) =>
          this.transformWebInvoice(invoice, ce)
        ),
        count: data.web_invoice_aggregate.aggregate.count,
      }))
    );
  }

  public getOneWebInvoice(id: string) {
    return forkJoin([
      this.apollo
        .query({
          query: GetOneWebInvoiceQuery,
          variables: { id },
        })
        .pipe(map((response) => response.data.web_invoice_by_pk)),
      this.store.select(CurrencyExchangeState.currencyExchange).pipe(
        filter((ce) => !!ce?.length),
        take(1)
      ),
    ]).pipe(map(([invoice, ce]) => this.transformWebInvoice(invoice, ce)));
  }

  public getNumOfUnpaidWebInvoices() {
    return this.apollo
      .query({
        query: GetNumOfUnpaidWebInvoicesQuery,
      })
      .pipe(
        map((response) => response.data.web_invoice_aggregate.aggregate.count)
      );
  }

  public createWebInvoice(variables: CreateWebInvoiceVariables) {
    return this.apollo
      .mutate({
        mutation: CreateWebInvoiceMutation,
        variables,
      })
      .pipe(map((response) => response.data?.create_web_invoice?.id));
  }

  private transformWebInvoice(
    rawInvoice: RawWebInvoice,
    currencyExchange: CurrencyExchange[]
  ): WebInvoice {
    const invoice = structuredClone(rawInvoice);

    if (invoice.transaction) {
      return {
        ...invoice,
        ...calculateExchange(
          {
            ...invoice.transaction,
            userCurrency: invoice.userCurrency,
          },
          currencyExchange
        ),
      };
    }

    return invoice;
  }
}
