import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, Router } from '@angular/router';
import { catchError, EMPTY, map } from 'rxjs';
import { ErrorHandlerService } from 'src/app/common/error/error-handler.service';
import { WebInvoiceService } from 'src/app/graphql/service/web-invoice.service';

export const webInvoiceResolver = (route: ActivatedRouteSnapshot) => {
  const router = inject(Router);
  const errorHandler = inject(ErrorHandlerService);
  const webInvoiceService = inject(WebInvoiceService);

  // redirect back to table if there is no ID
  // this shouldn't even happen, since the URL would not match
  if (!route.paramMap.has('id')) {
    router.navigate(['/web-invoice']);
    return EMPTY;
  }

  // read ID
  const id = route.paramMap.get('id') as string;

  // get invoice by ID
  return webInvoiceService.getOneWebInvoice(id).pipe(
    // redirect and error if operation fails
    catchError(() => {
      router.navigate(['/web-invoice']);
      errorHandler.handleError(
        new Error(`Unable to find invoice with uuid "${id}".`)
      );
      return EMPTY;
    })
  );
};

export const webInvoiceTitleResolver: ResolveFn<string> = (
  route: ActivatedRouteSnapshot
) => {
  return webInvoiceResolver(route).pipe(map((invoice) => invoice.title));
};
