import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { InMemoryCache } from '@apollo/client/cache';
import { ApolloLink } from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { ApolloModule as AM, APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { filter, firstValueFrom } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { environment } from 'src/environments/environment';
import { ErrorHandlerService } from '../error/error-handler.service';
import {
  AUTH_OPERATIONS,
  createHeaders,
  isAccessTokenInvalid,
  isInvalidRefreshToken,
  isSchemaValidationError,
} from './apollo.models';

export function createApollo(
  httpLink: HttpLink,
  errorHandler: ErrorHandlerService,
  authService: AuthService,
  router: Router,
  zone: NgZone
) {
  const errorLink = onError((req) => {
    const { operation, forward } = req;

    // access token is no longer valid
    if (isAccessTokenInvalid(req)) {
      authService.invalidateSession();
      return forward(operation);
    }

    // if refresh token is invalid
    // or schema is invalid (because auth token is missing)
    const isUnAuthorized =
      isSchemaValidationError(req) && !authService.hasAccessToken();
    if (isInvalidRefreshToken(req) || isUnAuthorized) {
      zone.run(() => router.navigate(['/login']));
      return void 0;
    }

    // handle regular errors
    errorHandler.handleError(req);
    return void 0;
  });

  const headerLink = setContext(async (req) => {
    // forward auth operations immediately
    if (req.operationName && AUTH_OPERATIONS.includes(req.operationName))
      return createHeaders();

    // wait for potential refresh operations to complete
    await firstValueFrom(
      authService.refreshInProgress$.pipe(filter((progress) => !progress))
    );

    // refresh token if possible
    if (!authService.hasAccessToken() && authService.hasRefreshToken()) {
      await firstValueFrom(authService.refreshSession());
    }

    // add headers with potential token
    return createHeaders(authService.accessToken);
  });

  const link = ApolloLink.from([
    errorLink,
    headerLink,
    httpLink.create({ uri: environment.API_URL }),
  ]);
  const cache = new InMemoryCache();

  return {
    link,
    cache,
  };
}

@NgModule({
  declarations: [],
  imports: [HttpClientModule, AM],
  providers: [
    HttpClient,
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink, ErrorHandlerService, AuthService, Router, NgZone],
    },
  ],
  exports: [HttpClientModule, AM],
})
export class ApolloModule {}
