import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Apollo } from 'apollo-angular';
import { filter, forkJoin, map, take } from 'rxjs';
import { CurrencyExchangeState } from '../../common/currency-exchange/state/currency-exchange.state';
import {
  calculateExchange,
  CurrencyExchange,
} from '../models/currency-exchange';
import { RawTransaction, Transaction } from '../models/transaction';
import {
  GetTransactionExportVariables,
  GetTransactionResponse,
  GetTransactionsQuery,
  GetTransactionVariables,
} from '../query/get-transactions.query';

@Injectable({
  providedIn: 'root',
})
export class TransactionService {
  constructor(private apollo: Apollo, private store: Store) {}

  public getTransactions<
    T extends GetTransactionVariables | GetTransactionExportVariables
  >(variables: T) {
    return forkJoin([
      this.apollo
        .query<GetTransactionResponse, T>({
          query: GetTransactionsQuery,
          variables,
          fetchPolicy: 'network-only',
        })
        .pipe(map((response) => response.data)),
      this.store.select(CurrencyExchangeState.currencyExchange).pipe(
        filter((ce) => !!ce?.length),
        take(1)
      ),
    ]).pipe(
      map(([data, ce]) => ({
        transactions: data.transaction.map((transaction) =>
          this.transformTransaction(transaction, ce)
        ),
        count: data.transaction_aggregate.aggregate.count,
      }))
    );
  }

  private transformTransaction(
    rawTransaction: RawTransaction,
    currencyExchange: CurrencyExchange[]
  ): Transaction {
    const transaction = structuredClone(rawTransaction);
    return {
      ...transaction,
      ...calculateExchange(transaction, currencyExchange),
    };
  }
}
